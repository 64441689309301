import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { BulbOutlined, FormOutlined, SmileOutlined } from "@ant-design/icons";
import { Steps, Button, Form, Row, Col, Layout, Card, Typography } from "antd";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

import RegistrationFormContainer from "./styles";

import { GET_APPLICATION_PANEL_DATA } from "../../graphql/queries";
import { NEW_REGISTRATION_MUTATION } from "../../graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";

import { Loading } from "components/Loading";

import useAuth from "auth";

const { Content } = Layout;
const { Title } = Typography;

const RegistrationFrom = () => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const { t } = useTranslation();

  const [current, setCurrent] = useState(0);

  const [submitting, setSubmitting] = useState(false);
  const [canNavigate, setCanNavigate] = useState(false);

  const errorMessageRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [form] = Form.useForm();

  const [formData, setFormData] = useState({});

  const [civilId, setCivilId] = useState(null);
  const [personalImage, setPersonalImage] = useState(null);
  const [ibanNumber, setIbanNumber] = useState(null);

  const { loading: applicationPanelDataLoading, data: applicationPanelData } = useQuery(
    GET_APPLICATION_PANEL_DATA,
    {},
  );

  const [newRegistrationMutation] = useMutation(NEW_REGISTRATION_MUTATION, {
    onCompleted: async (data) => {
      if (data.newRegistration.success) {
        setErrorMessage(null);
        localStorage.setItem("registeredSuccessfullyMessage", "true");
        setCanNavigate(true);
      } else {
        setErrorMessage(data.newRegistration.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (canNavigate) navigate(`/history/${user.userID}`);
  }, [canNavigate]);

  if (applicationPanelDataLoading) return <Loading />;

  const steps = [
    {
      title: t("registrationForm.stepOne.title"),
      content: <StepOne applicationPanelData={applicationPanelData} />,
      icon: <BulbOutlined />,
    },
    {
      title: t("registrationForm.stepTwo.title"),
      content: (
        <StepTwo
          civilId={civilId}
          setCivilId={setCivilId}
          personalImage={personalImage}
          setPersonalImage={setPersonalImage}
          ibanNumber={ibanNumber}
          setIbanNumber={setIbanNumber}
        />
      ),
      icon: <FormOutlined />,
    },
    {
      title: t("registrationForm.stepThree.title"),
      content: <StepThree applicationPanelData={applicationPanelData} />,
      icon: <SmileOutlined />,
    },
  ];

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData((prevData) => ({ ...prevData, ...values }));
        setCurrent(current + 1);
        // reset fields to avoid throwing an error when going back.
        form.setFieldsValue({ civilId: null, personalImage: null, ibanNumber: null });
      })
      .catch((info) => {
        // eslint-disable-next-line no-console
        console.error("Validation failed:", info);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const data = { ...formData, ...values };
        if (data.specialRequest == null || data.specialRequest === "") delete data.specialRequest;
        setSubmitting(true);
        newRegistrationMutation({
          variables: { ...data, civilId, personalImage, ibanNumber },
        });
      })
      .catch((info) => {
        // eslint-disable-next-line no-console
        console.log("Validation failed:", info);
      });
  };

  return (
    <RegistrationFormContainer>
      <Layout className="layout-default ant-layout layout-sign-up">
        <Content className="pt-0">
          <Title
            className="my-0"
            style={{
              fontSize: "45px",
              whiteSpace: "break-spaces",
              textAlign: "center",
            }}
          >
            {t("registrationForm.title")}
          </Title>

          <Card className="card-signup header-solid h-full ant-card pt-0" bordered="false">
            <div>
              <Steps current={current} className="my-5" items={steps} />

              <Form form={form} scrollToFirstError={true}>
                <div ref={errorMessageRef}></div> {/* Error message ref */}
                {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
                {steps[current].content}
                <Row justify="space-between" className="mt-3">
                  <Col>
                    {current > 0 && <Button onClick={prev}>{t("registrationForm.prevBtn")}</Button>}
                  </Col>

                  <Col>
                    {current < steps.length - 1 && (
                      <Button type="primary" onClick={next}>
                        {t("registrationForm.nextBtn")}
                      </Button>
                    )}

                    {current === steps.length - 1 && (
                      <Button
                        type="primary"
                        onClick={onSubmit}
                        loading={submitting}
                        disabled={submitting}
                      >
                        {t("registrationForm.submitBtn")}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
        </Content>
      </Layout>
    </RegistrationFormContainer>
  );
};

export default RegistrationFrom;
