import { Modal } from "antd";

import { useTranslation } from "react-i18next";

// import { REGISTRATION_QUESTION_ANSWERS } from "graphql/queries";
// import { useQuery } from "@apollo/client";

// import { Loading } from "components/Loading";

const RegistrationResponseModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  registrationID,
  registrationObj,
}) => {
  const { t } = useTranslation();

  // TODO: Remove as no longer needed.
  // const { loading: registrationResponseLoading, data: registrationResponseData } = useQuery(
  //   REGISTRATION_QUESTION_ANSWERS,
  //   { variables: { registrationId: registrationID } },
  // );

  return (
    <Modal
      title={t("dashboard.registrationFormResponseModal.modalTitle")}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      key={registrationID}
      width={800}
      footer={null}
    >
      <ul>
        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.valuesYouBelieveInField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>{registrationObj.valuesYouBelieveIn || "-"}</li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.howDidYouKnowAboutTheProgramField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.howDidYouKnowAboutTheProgram || "-"}
            </li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.civilIdField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.civilId ? (
                <a
                  style={{ fontFamily: '"Rubik", sans-serif' }}
                  href={registrationObj.civilId}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {registrationObj.civilId}
                </a>
              ) : (
                "-"
              )}
            </li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.personalImageField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.personalImage ? (
                <a
                  style={{ fontFamily: '"Rubik", sans-serif' }}
                  href={registrationObj.personalImage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {registrationObj.personalImage}
                </a>
              ) : (
                "-"
              )}
            </li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.ibanNumberField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.ibanNumber ? (
                <a
                  style={{ fontFamily: '"Rubik", sans-serif' }}
                  href={registrationObj.ibanNumber}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {registrationObj.ibanNumber}
                </a>
              ) : (
                "-"
              )}
            </li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.specialRequestField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>{registrationObj.specialRequest || "-"}</li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepThree.agreeToAttend")}
          <ul>
            <li style={{ fontWeight: "normal" }}>{registrationObj.programDates ? "✅" : "❎"}</li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepThree.correctInformation")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.correctInformation ? "✅" : "❎"}
            </li>
          </ul>
        </li>
      </ul>
    </Modal>
  );
};

export default RegistrationResponseModal;
