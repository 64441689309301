import { useTranslation } from "react-i18next";
import { Row, Col, Form, Checkbox } from "antd";

const StepThree = ({ applicationPanelData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            colon={false}
            name="programDates"
            valuePropName="checked"
            rules={[{ required: true, message: t("auth.signUp.requiredFieldMessage") }]}
          >
            <Checkbox>
              {t("registrationForm.stepThree.agreeToAttend")}
              <span
                style={{
                  margin: "0 4px",
                  color: "#ff4d4f",
                  fontSize: "14px",
                  fontFamily: "SimSun, sans-serif",
                  lineHeight: "1",
                  fontWeight: "bold",
                }}
              >
                *
              </span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <div
        className="arabic-field"
        dangerouslySetInnerHTML={{
          __html: applicationPanelData.applicationPanelData.programDates,
        }}
      />

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            colon={false}
            name="correctInformation"
            valuePropName="checked"
            rules={[{ required: true, message: t("auth.signUp.requiredFieldMessage") }]}
          >
            <Checkbox>
              {t("registrationForm.stepThree.correctInformation")}
              <span
                style={{
                  margin: "0 4px",
                  color: "#ff4d4f",
                  fontSize: "14px",
                  fontFamily: "SimSun, sans-serif",
                  lineHeight: "1",
                  fontWeight: "bold",
                }}
              >
                *
              </span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default StepThree;
