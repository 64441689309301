import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { EyeTwoTone, EyeInvisibleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Col, Tooltip } from "antd";

import { COMPANY_SIGNUP_MUTATION } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";

import useAuth from "../../auth";

const CompanyForm = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [companyLogo, setCompanyLogo] = useState(null);
  const [contactPersonNumber, setContactPersonNumber] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const errorMessageRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const { signIn } = useAuth();

  const [signupMutation] = useMutation(COMPANY_SIGNUP_MUTATION, {
    onCompleted: (data) => {
      if (data.companyRegister.success) {
        setErrorMessage(null);
        signIn({ token: data.companyRegister.token });
        navigate("/account/verify");
      } else {
        setErrorMessage(data.companyRegister.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const onFinish = (values) => {
    setSubmitting(true);
    signupMutation({
      variables: { ...values, userType: "Company", logo: companyLogo, contactPersonNumber },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed} scrollToFirstError={true}>
      <div ref={errorMessageRef}></div> {/* Error message ref */}
      {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={16}>
          <Form.Item
            label={t("auth.signUp.emailField")}
            name="email"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
              {
                type: "email",
                message: t("auth.signUp.validEmailAddressMessage"),
              },
            ]}
          >
            <Input className="english-field" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.passwordField")}
            name="password"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              className="english-field"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.confirmPasswordField")}
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              className="english-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.companyNameEnField")}
            name="nameEn"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input maxLength={50} showCount className="english-field" />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.companyNameArField")}
            name="nameAr"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input maxLength={50} showCount className="arabic-field" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                {t("auth.signUp.companyDescriptionEnField")}
                <Tooltip title={t("auth.signUp.companyDescriptionTooltip")}>
                  <InfoCircleOutlined className={currentLanguage === "ar" ? "me-1" : "ms-1"} />
                </Tooltip>
              </div>
            }
            name="descriptionEn"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input.TextArea rows={5} className="english-field" />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                {t("auth.signUp.companyDescriptionArField")}
                <Tooltip title={t("auth.signUp.companyDescriptionTooltip")}>
                  <InfoCircleOutlined className={currentLanguage === "ar" ? "me-1" : "ms-1"} />
                </Tooltip>
              </div>
            }
            name="descriptionAr"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input.TextArea rows={5} className="arabic-field" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.contactPersonNameField")}
            name="contactPersonName"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input maxLength={50} showCount />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.contactPersonNumberField")}
            name="contactPersonNumber"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <PhoneInput
              className="english-field"
              country="kw"
              placeholder=""
              excludeCountries={["il"]}
              preferredCountries={["kw"]}
              onChange={(value, data) => {
                const countryCode = data.dialCode;
                const index = value.indexOf(countryCode);
                const length = countryCode.length;
                setContactPersonNumber(countryCode + "-" + value.slice(index + length));
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            label={t("auth.signUp.companyLogoField")}
            name="logo"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(event) => {
                setCompanyLogo(event.target.files[0]);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item className="text-center">
            <Button
              style={{ width: "60%" }}
              type="primary"
              htmlType="submit"
              loading={submitting}
              disabled={submitting}
            >
              {t("auth.signUp.signUpBtn")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default CompanyForm;
