import { useTranslation } from "react-i18next";

import { Row, Col, Form } from "antd";

const StepOne = ({ applicationPanelData }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item label={t("dashboard.applicationPanelForm.aboutProgram")}>
            <div
              className="arabic-field"
              dangerouslySetInnerHTML={{
                __html: applicationPanelData.applicationPanelData.aboutProgram,
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item label={t("dashboard.applicationPanelForm.applicationRequirements")}>
            <div
              className="arabic-field"
              dangerouslySetInnerHTML={{
                __html: applicationPanelData.applicationPanelData.applicationRequirements,
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item label={t("dashboard.applicationPanelForm.generalNotes")}>
            <div
              className="arabic-field"
              dangerouslySetInnerHTML={{
                __html: applicationPanelData.applicationPanelData.generalNotes,
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default StepOne;
