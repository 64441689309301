import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { Table, Button, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import moment from "moment";

import RequestDetailsModal from "./RequestDetailsModal";
import AssignedInternsModal from "./AssignedInternsModal";
import JobOpportunitiesModal from "./JobOpportunitiesModal";

const RequestsTable = ({ totalCount, data, loading, setPageNumber }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const navigate = useNavigate();

  const [requestDetailsModalOpen, setRequestDetailsModalOpen] = useState(false);
  const [assignedInternsModalOpen, setAssignedInternsModalOpen] = useState(false);
  const [jobOpportunitiesModalOpen, setJobOpportunitiesModalOpen] = useState(false);

  const [requestObj, setRequestObj] = useState(null);

  const handleMoreDetailsBtnClick = (requestObj) => {
    setRequestDetailsModalOpen(true);
    setRequestObj(requestObj);
  };

  const handleAssignedInternsBtnClick = (requestObj) => {
    setAssignedInternsModalOpen(true);
    setRequestObj(requestObj);
  };

  const handleJobOpportunitiesBtnClick = (requestObj) => {
    setJobOpportunitiesModalOpen(true);
    setRequestObj(requestObj);
  };

  const getMenuItems = (obj) => {
    return [
      {
        key: "more-details",
        label: (
          <StyledMenuItem type="text" onClick={() => handleMoreDetailsBtnClick(obj.node)}>
            {t("dashboard.requestsTable.moreDetailsBtn")}
          </StyledMenuItem>
        ),
      },
      {
        key: "job-opportunities",
        label: (
          <StyledMenuItem type="text" onClick={() => handleJobOpportunitiesBtnClick(obj.node)}>
            {t("dashboard.requestsTable.jobOpportunitiesBtn")}
          </StyledMenuItem>
        ),
      },
      {
        key: "edit-request",
        label: (
          <StyledMenuItem type="text" onClick={() => navigate(`/requests/${obj.node.pk}`)}>
            {t("dashboard.requestsTable.editRequestBtn")}
          </StyledMenuItem>
        ),
      },
      {
        key: "assigned-interns",
        label: (
          <StyledMenuItem type="text" onClick={() => handleAssignedInternsBtnClick(obj.node)}>
            {t("dashboard.requestsTable.viewAssignedInternsBtn")}
          </StyledMenuItem>
        ),
      },
    ];
  };

  const columns = [
    {
      title: t("dashboard.requestsTable.companyName"),
      key: "companyName",
      sorter: (a, b) => a.node.company.nameEn.length - b.node.company.nameEn.length,
      render: (obj) => obj.node.company["name" + (currentLanguage === "en" ? "En" : "Ar")],
    },
    {
      title: t("dashboard.requestsTable.contactPersonName"),
      key: "contactPersonName",
      sorter: (a, b) =>
        a.node.company.contactPersonName.length - b.node.company.contactPersonName.length,
      render: (obj) => obj.node.company.contactPersonName,
    },
    {
      title: t("dashboard.requestsTable.contactPersonNumber"),
      key: "contactPersonNumber",
      render: (obj) => (
        <span style={{ unicodeBidi: "embed", direction: "ltr" }}>
          {obj.node.company.contactPersonNumber}
        </span>
      ),
    },
    {
      title: t("dashboard.requestsTable.requestDate"),
      key: "createdAt",
      sorter: (a, b) => moment(a.node.createdAt).unix() - moment(b.node.createdAt).unix(),
      render: (obj) => moment(obj.node.createdAt).format("DD-MM-YYYY"),
    },
    {
      title: t("dashboard.requestsTable.requestedGender"),
      key: "requestedGender",
      filters: [
        {
          text: t("dashboard.requestsTable.MalesOnly"),
          value: "Males Only",
        },
        {
          text: t("dashboard.requestsTable.FemalesOnly"),
          value: "Females Only",
        },
        {
          text: t("dashboard.requestsTable.MalesAndFemales"),
          value: "Males and Females",
        },
      ],
      onFilter: (value, obj) => obj.node.requestedGender.indexOf(value) === 0,
      render: (obj) =>
        t(`dashboard.requestsTable.${obj.node.requestedGender?.replaceAll(" ", "")}`),
    },
    {
      title: t("dashboard.requestsTable.totalRequestedMales"),
      key: "totalRequestedMales",
      sorter: (a, b) => a.node.totalRequestedMales - b.node.totalRequestedMales,
      render: (obj) => obj.node.totalRequestedMales,
    },
    {
      title: t("dashboard.requestsTable.totalRequestedFemales"),
      key: "totalRequestedFemales",
      sorter: (a, b) => a.node.totalRequestedFemales - b.node.totalRequestedFemales,
      render: (obj) => obj.node.totalRequestedFemales,
    },
    {
      title: t("dashboard.requestsTable.totalAssignedMales"),
      key: "totalAssignedMales",
      sorter: (a, b) => a.node.totalAssignedMales - b.node.totalAssignedMales,
      render: (obj) => obj.node.totalAssignedMales,
    },
    {
      title: t("dashboard.requestsTable.totalAssignedFemales"),
      key: "totalAssignedFemales",
      sorter: (a, b) => a.node.totalAssignedFemales - b.node.totalAssignedFemales,
      render: (obj) => obj.node.totalAssignedFemales,
    },
    {
      title: t("dashboard.requestsTable.actions"),
      key: "actions",
      render: (obj) => {
        const items = getMenuItems(obj);
        return (
          <StyledDropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
            <StyledMenuIcon icon={<MoreOutlined />}></StyledMenuIcon>
          </StyledDropdown>
        );
      },
    },
  ];

  return (
    <div style={{ overflowX: "clip" }}>
      <Table
        columns={columns}
        pagination={{
          position: ["bottomRight"],
          pageSize: 50,
          total: totalCount,
          onChange: (newPage) => {
            setPageNumber(newPage);
          },
        }}
        dataSource={data.map((item) => ({ ...item, key: item.node.pk }))}
        scroll={{ x: true }}
        loading={loading}
      />

      {requestObj && (
        <RequestDetailsModal
          isModalOpen={requestDetailsModalOpen}
          handleOk={() => setRequestDetailsModalOpen(false)}
          handleCancel={() => setRequestDetailsModalOpen(false)}
          requestID={requestObj.pk}
        />
      )}

      {requestObj && (
        <AssignedInternsModal
          isModalOpen={assignedInternsModalOpen}
          handleOk={() => setAssignedInternsModalOpen(false)}
          handleCancel={() => setAssignedInternsModalOpen(false)}
          requestID={requestObj.pk}
        />
      )}

      {requestObj && (
        <JobOpportunitiesModal
          isModalOpen={jobOpportunitiesModalOpen}
          handleOk={() => setJobOpportunitiesModalOpen(false)}
          handleCancel={() => setJobOpportunitiesModalOpen(false)}
          requestID={requestObj.pk}
        />
      )}
    </div>
  );
};

export default RequestsTable;

const StyledDropdown = styled(Dropdown)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 40px;
  border: none;
  box-shadow: none;

  svg {
    margin-right: 0;
  }
`;

const StyledMenuItem = styled(Button)`
  box-shadow: none;
  border-radius: none;

  &:hover {
    background: none !important;
  }
`;

const StyledMenuIcon = styled(Button)`
  border-radius: 50px;

  &:hover {
    background: #35b4a7 !important;

    svg {
      color: #fff;
    }
  }
`;
