import { Col, Form, Input, Row } from "antd";

import { useTranslation } from "react-i18next";

const StepTwo = ({
  civilId,
  setCivilId,
  personalImage,
  setPersonalImage,
  ibanNumber,
  setIbanNumber,
}) => {
  const { t } = useTranslation();

  // const [guardianMobileNumber, setGuardianMobileNumber] = useState(null);
  // const [emergencyContactNumber, setEmergencyContactNumber] = useState(null);

  // const [languages, setLanguages] = useState([]);
  // const [chronicDiseasesYesNo, setChronicDiseasesYesNoField] = useState(null);

  return (
    <>
      {/* <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.guardianMobileNumberField")}
            name="guardianMobileNumber"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <PhoneInput
              className="english-field"
              country="kw"
              placeholder=""
              excludeCountries={["il"]}
              preferredCountries={["kw"]}
              onChange={(value, data) => {
                const countryCode = data.dialCode;
                const index = value.indexOf(countryCode);
                const length = countryCode.length;
                setGuardianMobileNumber(countryCode + "-" + value.slice(index + length));
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.guardianRelationField")}
            name="guardianRelation"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.emergencyContactNumberField")}
            name="emergencyContactNumber"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <PhoneInput
              className="english-field"
              country="kw"
              placeholder=""
              excludeCountries={["il"]}
              preferredCountries={["kw"]}
              onChange={(value, data) => {
                const countryCode = data.dialCode;
                const index = value.indexOf(countryCode);
                const length = countryCode.length;
                setEmergencyContactNumber(countryCode + "-" + value.slice(index + length));
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.emergencyContactRelationField")}
            name="emergencyContactRelation"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.languagesField")}
            name="languages"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
            initialValue=""
          >
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={(checkedValues) => {
                setLanguages(checkedValues);
              }}
            >
              <Row className="mb-1 mx-0">
                <Col>
                  <Checkbox value="Arabic">{t("auth.signUp.arabicOption")}</Checkbox>
                </Col>
              </Row>

              <Row className="mb-1 mx-0">
                <Col>
                  <Checkbox value="English">{t("auth.signUp.englishOption")}</Checkbox>
                </Col>
              </Row>

              <Row className="mb-1 mx-0">
                <Col>
                  <Checkbox value="Others">{t("auth.signUp.othersOption")}</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>

        {languages.includes("Others") && (
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.otherLanguagesField")}
              name="otherLanguages"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t("auth.signUp.chronicDiseasesYesNoField")}
            name="chronicDiseasesYesNo"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
            initialValue=""
          >
            <Select onChange={(value) => setChronicDiseasesYesNoField(value)}>
              <Option key="" value="">
                -----
              </Option>
              ,
              <Option key="Yes" value="Yes">
                {t("auth.signUp.yesOption")}
              </Option>
              ,
              <Option key="No" value="No">
                {t("auth.signUp.noOption")}
              </Option>
              ,
            </Select>
          </Form.Item>
        </Col>

        {chronicDiseasesYesNo === "Yes" && (
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.chronicDiseasesField")}
              name="chronicDiseases"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row> */}

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            label={t("registrationForm.stepTwo.valuesYouBelieveInField")}
            name="valuesYouBelieveIn"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            label={t("registrationForm.stepTwo.howDidYouKnowAboutTheProgramField")}
            name="howDidYouKnowAboutTheProgram"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <>
                {t("registrationForm.stepTwo.civilIdField")}
                &nbsp;
                <span className="english-field">(.PNG, .JPEG, .JPG)</span>
              </>
            }
            name="civilId"
            help={t("registrationForm.stepTwo.helpText.fileSize")}
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!civilId)
                    return Promise.reject(new Error(t("auth.signUp.requiredFieldMessage")));

                  if (civilId.size > 1024 * 1024)
                    return Promise.reject(
                      new Error(t("registrationForm.stepTwo.helpText.fileSizeError")),
                    );

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(event) => {
                setCivilId(event.target.files[0]);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <>
                {t("registrationForm.stepTwo.personalImageField")}
                &nbsp;
                <span className="english-field">(.PNG, .JPEG, .JPG)</span>
              </>
            }
            help={t("registrationForm.stepTwo.helpText.fileSize")}
            name="personalImage"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!personalImage)
                    return Promise.reject(new Error(t("auth.signUp.requiredFieldMessage")));

                  if (personalImage.size > 1024 * 1024)
                    return Promise.reject(
                      new Error(t("registrationForm.stepTwo.helpText.fileSizeError")),
                    );

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(event) => {
                setPersonalImage(event.target.files[0]);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            label={t("registrationForm.stepTwo.ibanNumberField")}
            help={
              <>
                <small>{t("registrationForm.stepTwo.helpText.onlyPDF")}</small>
                <br />
                <small>{t("registrationForm.stepTwo.helpText.fileSize")}</small>
                <br />
                <small>{t("registrationForm.stepTwo.helpText.parentAccount")}</small>
                <br />
                <small>{t("registrationForm.stepTwo.helpText.bankCard")}</small>
              </>
            }
            name="ibanNumber"
            rules={[
              {
                required: true,
                message: t("auth.signUp.requiredFieldMessage"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!ibanNumber)
                    return Promise.reject(new Error(t("auth.signUp.requiredFieldMessage")));

                  if (ibanNumber.size > 1024 * 1024)
                    return Promise.reject(
                      new Error(t("registrationForm.stepTwo.helpText.fileSizeError")),
                    );

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <input
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setIbanNumber(event.target.files[0]);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Form.Item
            label={t("registrationForm.stepTwo.specialRequestField")}
            name="specialRequest"
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default StepTwo;
