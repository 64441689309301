import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import moment from "moment";

import { Col, Card, Tag, Typography, Space, Button } from "antd";
import { ClockCircleOutlined, PushpinOutlined } from "@ant-design/icons";

import {
  getRegistrationPaymentStatusColor,
  getRegistrationStatusColor,
} from "helpers/registration";

import { CAN_CHECK_IN_OUT_MUTATION, NEW_ATTENDANCE_RECORD } from "../../../../graphql/mutations";
import { useMutation } from "@apollo/client";

import { formatTime, getUserLocation } from "helpers/common";

import ChooseJopOpportunityModal from "./ChooseJopOpportunityModal";

const { Title, Text } = Typography;

const HistoryCard = ({ registrationObj, refetchData, gender }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const status = registrationObj.status;
  const paymentStatus = registrationObj.paymentStatus;

  const [isChooseJopOpportunityModalOpen, setChooseJopOpportunityModalOpen] = useState(false);

  const [canCheckIn, setCanCheckIn] = useState(false);
  const [canCheckOut, setCanCheckOut] = useState(false);

  const [canCheckInOutMutation] = useMutation(CAN_CHECK_IN_OUT_MUTATION, {
    onCompleted: (data) => {
      if (data.canCheckInOut.success) {
        setCanCheckIn(data.canCheckInOut.canCheckIn);
        setCanCheckOut(data.canCheckInOut.canCheckOut);
      } else {
        // eslint-disable-next-line no-console
        console.error(data.canCheckInOut.errors[0]);
      }
    },
  });

  useEffect(() => {
    canCheckInOutMutation({
      variables: { registrationId: registrationObj.id, date: moment().format("YYYY-MM-DD") },
    });
  }, []);

  const [actionsArray, setActionsArray] = useState([]);

  useEffect(() => {
    const array = [];

    // participant can choose a jop opportunity once approved.
    if (status === "APPROVED" && !registrationObj.assignedJobOpportunity)
      array.push(
        <Button key="chooseJobOpportunity" onClick={() => setChooseJopOpportunityModalOpen(true)}>
          {t("auth.participantProfile.historyCard.chooseJobOpportunityBtn")}
        </Button>,
      );

    // payment will be activated once approved and a job opportunity is chosen.
    // wrap button in <Link key="payNow" to={`/register/${registrationObj.id}/pay`}></Link>
    if (status === "APPROVED" && paymentStatus !== "PAID" && registrationObj.assignedJobOpportunity)
      array.push(<Button disabled>{t("auth.participantProfile.historyCard.payNowBtn")}</Button>);

    if (canCheckIn)
      array.push(
        <Button key="checkIn" onClick={(event) => handleBtnClick(event, "checkIn")}>
          {t("auth.participantProfile.historyCard.checkInBtn")}
        </Button>,
      );

    if (canCheckOut)
      array.push(
        <Button key="checkOut" onClick={(event) => handleBtnClick(event, "checkOut")}>
          {t("auth.participantProfile.historyCard.checkOutBtn")}
        </Button>,
      );

    setActionsArray(array);
  }, [registrationObj, canCheckIn, canCheckOut]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const errorMessageRef = useRef(null);
  const successMessageRef = useRef(null);

  const [newAttendanceRecord] = useMutation(NEW_ATTENDANCE_RECORD, {
    onCompleted: (data) => {
      if (data.newAttendanceRecord.success) {
        setErrorMessage(null);
        if (data.newAttendanceRecord.eventType === "check_in") {
          setSuccessMessage(t("auth.participantProfile.historyCard.checkedInSuccessfullyMessage"));
          setCanCheckIn(false);
        }
        if (data.newAttendanceRecord.eventType === "check_out") {
          setSuccessMessage(t("auth.participantProfile.historyCard.checkedOutSuccessfullyMessage"));
          setCanCheckOut(false);
        }
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000); // Clear success message after 5 seconds
      } else {
        // eslint-disable-next-line no-console
        setErrorMessage(data.newAttendanceRecord.errors[0]);
        setSuccessMessage(null);
        scrollToError();
      }
    },
  });

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleBtnClick = (event, eventType) => {
    getUserLocation()
      .then((geoLocation) => {
        if (geoLocation.error) {
          setErrorMessage(geoLocation.error);
          scrollToError();
        } else {
          setErrorMessage(null);
          const data = {
            registrationId: registrationObj.id,
            date: moment().format("YYYY-MM-DD"),
          };
          if (eventType === "checkIn") {
            data["checkInTime"] = moment().format("HH:mm");
            data["checkInLatitude"] = geoLocation.latitude;
            data["checkInLongitude"] = geoLocation.longitude;
          }
          if (eventType === "checkOut") {
            data["checkOutTime"] = moment().format("HH:mm");
            data["checkOutLatitude"] = geoLocation.latitude;
            data["checkOutLongitude"] = geoLocation.longitude;
          }
          newAttendanceRecord({
            variables: { ...data },
          });
        }
      })
      .catch((geoLocation) => {
        setErrorMessage(geoLocation.error);
        scrollToError();
      });
  };

  return (
    <Col xs={24} className="mt-5">
      <Card
        title={
          <>
            {t("auth.participantProfile.historyCard.applicationDate") +
              moment(registrationObj.createdAt).format("DD/MM/YYYY")}
            <div ref={errorMessageRef}></div> {/* Error message ref */}
            {errorMessage && <p className="text-danger english-field mb-0">{errorMessage}</p>}
            <div ref={successMessageRef}></div> {/* Success message ref */}
            {successMessage && <p className="text-success mb-0">{successMessage}</p>}
          </>
        }
        bordered={false}
        actions={actionsArray}
      >
        <Space direction="vertical">
          <Text>
            {t("auth.participantProfile.historyCard.status")}

            <Tag color={getRegistrationStatusColor(registrationObj.status)}>
              {t(
                `auth.participantProfile.historyCard.${registrationObj.status.toLowerCase()}`,
              ).toUpperCase()}
            </Tag>
          </Text>

          <Text>
            {t("auth.participantProfile.historyCard.paymentStatus")}

            <Tag color={getRegistrationPaymentStatusColor(registrationObj.paymentStatus)}>
              {t(
                `auth.participantProfile.historyCard.${registrationObj.paymentStatus.toLowerCase()}`,
              ).toUpperCase()}
            </Tag>
          </Text>
        </Space>
        {registrationObj.assignedJobOpportunity && (
          <Card
            type="inner"
            title={t("auth.participantProfile.historyCard.assignedJobOpportunity")}
            className="mt-3"
          >
            <Space direction="vertical">
              <Text strong>
                {
                  registrationObj.assignedJobOpportunity.companyRequest[
                    "companyName" + (currentLanguage === "en" ? "En" : "Ar")
                  ]
                }
              </Text>

              <Title level={5}>{registrationObj.assignedJobOpportunity.jobTitle}</Title>

              <Text>
                <ClockCircleOutlined className={currentLanguage === "en" ? "me-2" : "ms-2"} />
                {formatTime(registrationObj.assignedJobOpportunity.startingTime, currentLanguage)}
                &nbsp; - &nbsp;
                {formatTime(registrationObj.assignedJobOpportunity.endingTime, currentLanguage)}
              </Text>

              <a
                href={registrationObj.assignedJobOpportunity.location.url}
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text>
                  <PushpinOutlined className={currentLanguage === "en" ? "me-2" : "ms-2"} />
                  {registrationObj.assignedJobOpportunity.location.address}
                </Text>
              </a>

              <Text>{registrationObj.assignedJobOpportunity.jobDescription}</Text>
            </Space>
          </Card>
        )}
      </Card>

      <ChooseJopOpportunityModal
        isModalOpen={isChooseJopOpportunityModalOpen}
        handleOk={() => setChooseJopOpportunityModalOpen(false)}
        handleCancel={() => setChooseJopOpportunityModalOpen(false)}
        registrationID={registrationObj.id}
        gender={gender}
        refetchData={refetchData}
      />
    </Col>
  );
};

export default HistoryCard;
