import { useQuery } from "@apollo/client";
import { Col, Flex, Row, Button } from "antd";
import { FilterOutlined, WhatsAppOutlined } from "@ant-design/icons";
import SearchBar from "components/inputs/SearchBar";
import { GET_REGISTRATIONS } from "graphql/registrations/queries";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetRegistrations } from "types/Registration";
import SendWhatsAppMessageModal from "components/Dashboard/Registrations/SendWhatsAppMessageModal";
import RegistrationsTable from "../components/Dashboard/Registrations";

const RegistrationsDashboardPage = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [isSendWhatsAppModalOpen, setSendWhatAppModalOpen] = useState<boolean>(false);

  const { data, loading, refetch } = useQuery<GetRegistrations>(GET_REGISTRATIONS, {
    variables: { search: searchQuery },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Flex
        vertical
        gap="middle"
        style={{
          backgroundColor: "white",
          padding: 24,
        }}
      >
        <Row
          className="searchbar-row"
          align="middle"
          gutter={[24, 24]}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col xs={24} lg={14}>
            <SearchBar
              placeholder={t("dashboard.searchPlaceholder")}
              value={searchQuery}
              onChange={handleChange}
            />
          </Col>

          {/* <Col
            xs={24} // Make sure it is 24 on xs to avoid wrapping issues
            lg={7}
            style={{
              textAlign: "left", // Ensure text alignment is left
              display: "flex",
              justifyContent: "flex-start", // Align content to the start (left)
            }}
          >
            <Button
              type="primary"
              icon={<WhatsAppOutlined />}
              style={{
                borderRadius: "50px",
                width: "100%",
              }}
              onClick={() => setSendWhatAppModalOpen(true)}
            >
              {t("dashboard.whatsAppButton")}
            </Button>
          </Col> */}
        </Row>

        {/* <Col
            xs={10}
            lg={7}
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="primary"
              icon={<FilterOutlined />}
              style={{
                borderRadius: "50px",
                width: "100%",
              }}
            >
              {t("dashboard.filterButton")}
            </Button>
          </Col> */}

        <Row>
          <Col xs={24}>
            <RegistrationsTable
              data={data?.registrations || []}
              loading={loading}
              refetchData={refetch}
            />
          </Col>
        </Row>
      </Flex>

      <SendWhatsAppMessageModal
        isModalOpen={isSendWhatsAppModalOpen}
        handleOk={() => setSendWhatAppModalOpen(false)}
        handleCancel={() => setSendWhatAppModalOpen(false)}
      />
    </>
  );
};

export default RegistrationsDashboardPage;
