import { UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Avatar, Button, Card, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../auth";

import { GET_APPLICATION_PANEL_DATA } from "../../../graphql/queries";

const { Title } = Typography;

const ApplyNowCard = ({ profileInfo, showApplyNowBtn }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const navigate = useNavigate();

  const { user } = useAuth();

  const [message, setMessage] = useState(null);

  const { loading: applicationPanelDataLoading, data: applicationPanelData } = useQuery(
    GET_APPLICATION_PANEL_DATA,
    {},
  );

  const hasCompletedProfile = !!profileInfo;

  useEffect(() => {
    if (!applicationPanelData && !hasCompletedProfile) return;

    const ageFrom = applicationPanelData?.applicationPanelData?.participantsAgeFrom;
    const ageTo = applicationPanelData?.applicationPanelData?.participantsAgeTo;

    if (
      profileInfo?.age > ageTo ||
      profileInfo?.age < ageFrom ||
      profileInfo?.countryOfResidence !== "Kuwait" ||
      (profileInfo?.nationality !== "Kuwait" && profileInfo?.motherNationality !== "Kuwait")
    )
      setMessage(t("auth.participantProfile.doNotMeetRequirements"));
    else setMessage(null);
  }, [profileInfo, applicationPanelData, t]);

  const canApply = () => {
    return !message && hasCompletedProfile && user.isVerified;
  };

  const buttonTooltip = applicationPanelDataLoading
    ? t("auth.participantProfile.dataLoading")
    : canApply()
      ? null
      : message;

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="d-flex justify-content-center mb-5">
        <Avatar size={180} icon={<UserOutlined />} />
      </div>

      <div>
        <Title level={4} style={{ textAlign: "center" }}>
          {hasCompletedProfile && user.isVerified ? (
            profileInfo["fullName" + (currentLanguage === "en" ? "En" : "Ar")]
          ) : !user.isVerified ? (
            <>
              <ExclamationCircleOutlined
                style={{
                  color: "#f5222d",
                  marginRight: currentLanguage === "en" ? 8 : 0,
                  marginLeft: currentLanguage === "ar" ? 8 : 0,
                }}
              />

              {t("auth.participantProfile.verifyYourAccount")}

              <Button
                type="link"
                className="verify-email-btn"
                onClick={() =>
                  navigate("/account/verify", { state: { sendEmailOnUseEffect: true } })
                }
              >
                {t("auth.participantProfile.clickHere")}
              </Button>

              {t("auth.participantProfile.toApply")}
            </>
          ) : (
            <>
              <ExclamationCircleOutlined
                style={{
                  color: "#f5222d",
                  marginRight: currentLanguage === "en" ? 8 : 0,
                  marginLeft: currentLanguage === "ar" ? 8 : 0,
                }}
              />
              {t("auth.participantProfile.completeYourProfileToApply")}
            </>
          )}
        </Title>
      </div>

      <div>
        <Title level={5} style={{ textAlign: "center" }}>
          {t("auth.participantProfile.waedIntern")}
        </Title>
      </div>

      <div>
        {profileInfo && (
          <Title level={5} style={{ textAlign: "center" }}>
            {t("auth.participantProfile.waedID")}
            <span className="english-field">{profileInfo["participantId"]}</span>
          </Title>
        )}
      </div>

      {showApplyNowBtn && (
        <Tooltip title={buttonTooltip}>
          <Button
            type="primary"
            style={{
              width: "80%",
              display: "block",
              fontSize: "16px",
            }}
            className="mx-auto my-4"
            onClick={() => navigate("/register")}
            disabled={applicationPanelDataLoading || !canApply()}
          >
            {t("auth.participantProfile.applyNowBtn")}
          </Button>
        </Tooltip>
      )}
    </Card>
  );
};

export default ApplyNowCard;
